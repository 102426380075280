import React from "react";

const Pencil = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.7378 7.97205L5.69976 17.0101L5.10876 19.0261L7.08376 18.4551L16.1518 9.38705L14.7378 7.97205ZM16.2798 6.43005L17.6938 7.84405L19.0118 6.52605C19.1055 6.43229 19.1582 6.30514 19.1582 6.17255C19.1582 6.03997 19.1055 5.91282 19.0118 5.81905L18.3038 5.11205C18.21 5.01832 18.0828 4.96566 17.9503 4.96566C17.8177 4.96566 17.6905 5.01832 17.5968 5.11205L16.2808 6.43005H16.2798ZM19.7188 3.69805L20.4258 4.40505C20.8944 4.87387 21.1577 5.50964 21.1577 6.17255C21.1577 6.83546 20.8944 7.47123 20.4258 7.94005L8.13376 20.2331L3.91376 21.4531C3.74175 21.5026 3.55958 21.5052 3.38626 21.4603C3.21295 21.4155 3.05485 21.3249 2.92847 21.1982C2.80209 21.0714 2.71206 20.913 2.66779 20.7395C2.62351 20.5661 2.62661 20.3839 2.67676 20.2121L3.92476 15.9571L16.1848 3.69705C16.6536 3.22838 17.2893 2.96509 17.9523 2.96509C18.6152 2.96509 19.2509 3.22838 19.7198 3.69705L19.7188 3.69805Z" />
  </svg>
);

export default Pencil;
