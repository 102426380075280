import React from "react";

const Magic = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-eye"
  >
    <path d="M11.4329 6.5042L14.0842 3.8522L13.5539 3.32195L10.9027 5.9732L11.4329 6.5042ZM10.3717 7.5647L9.84143 7.03445L2.94818 13.9277L3.47843 14.4579L10.3724 7.56394L10.3717 7.5647ZM14.0842 1.7312L15.6749 3.3212C15.7447 3.39085 15.8 3.47357 15.8377 3.56462C15.8755 3.65566 15.8949 3.75326 15.8949 3.85182C15.8949 3.95038 15.8755 4.04798 15.8377 4.13903C15.8 4.23007 15.7447 4.31279 15.6749 4.38245L4.00793 16.0502C3.86729 16.1908 3.67656 16.2698 3.47768 16.2698C3.27881 16.2698 3.08808 16.1908 2.94743 16.0502L1.35668 14.4602C1.28695 14.3905 1.23163 14.3078 1.19389 14.2168C1.15615 14.1257 1.13672 14.0281 1.13672 13.9296C1.13672 13.831 1.15615 13.7334 1.19389 13.6424C1.23163 13.5513 1.28695 13.4686 1.35668 13.3989L13.0237 1.7312C13.1643 1.59059 13.3551 1.51161 13.5539 1.51161C13.7528 1.51161 13.9435 1.59059 14.0842 1.7312ZM7.19018 1.2002L8.23493 1.7282L9.31118 1.2002L8.80118 2.2952L9.31118 3.3212L8.27318 2.8232L7.19018 3.3212L7.70693 2.2562L7.18943 1.2002H7.19018ZM14.6152 6.5027L15.6599 7.03145L16.7362 6.50345L16.2262 7.59844L16.7362 8.62444L15.6982 8.12644L14.6144 8.62444L15.1319 7.55945L14.6144 6.50345L14.6152 6.5027Z" />
  </svg>
);

export default Magic;
